import axios from 'axios';
import { useEffect } from 'react';
import { getUserDetails } from 'src/queries/user/getUserDetails';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import { useTrackTitanStore } from 'src/zustand/store';

export const useFetchTestUser = () => {
    const { testUser } = useTrackTitanHydratedStore((state) => state.auth);
    const { setTestUser } = useTrackTitanStore((state) => state.auth);

    const fetchTestUser = async () => {
        const testUserId = process.env.NEXT_PUBLIC_DUMMY_ACCOUNT_UUID;

        const data = await getUserDetails(testUserId);

        setTestUser({
            uuid: data?.id,
            displayName: data?.username,
            userType: 'sim_racer'
        });
    };

    // set up test user details
    useEffect(() => {
        if (testUser === undefined) {
            fetchTestUser();
        }
    }, [testUser]);
};
