import { ICredentials } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { RawUserType, UserType } from 'src/types';
import { useTrackTitanStore } from 'src/zustand/store';
import * as ga from 'src/analytics/utils/google';
import { getUsername } from './useGetUsername';
import Cookies from 'js-cookie';
import { getUserBenefitData } from './getUserBenefitData';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import { getUserDetails } from 'src/queries/user/getUserDetails';

interface IAuthEvent {
    payload: {
        event: string;
        data?: any;
    };
}

export const getUser = async (
    setUser: (user: UserType | undefined) => void,
    setUserCredentials: (userCredentials: ICredentials | undefined) => void,
    setIsCheckingAuth: (isCheckingAuth: boolean) => void,
    bypassCache = false
) => {
    try {
        // cognito data
        const userData: RawUserType = await Auth.currentAuthenticatedUser({ bypassCache });
        const credentials = await Auth.currentCredentials();
        const userDetails = await getUserDetails(userData.attributes.sub);

        Cookies.set('tt-user-id', userData.attributes.sub, { expires: 365, sameSite: 'strict' });

        ga.set({ user_id: userData.attributes.sub });

        let user: UserType = {
            email: userData.attributes.email,
            uuid: userData.attributes.sub,
            cognitoUsername: userData.attributes.preferred_username,
            auth: {
                accessToken: userData.signInUserSession.idToken.jwtToken,
                accessTokenExpiresAt: userData.signInUserSession.idToken.payload.exp
            },
            profilePicTimestamp: Date.now(),
            userType: userDetails?.user_type ?? 'sim_racer',
            quizDuelUser: userData.attributes['custom:quiz_duel_user'] === 'true',
            identities: userData.attributes.identities ? JSON.parse(userData.attributes.identities) : []
        };

        user = { ...user, ...(await getUserBenefitData(userData.attributes.sub)) };

        const userWithDisplayName = await getUsername(user);

        setUser(userWithDisplayName);
        setUserCredentials(credentials);
    } catch (e) {
        const dummyAccountId = process.env.NEXT_PUBLIC_DUMMY_ACCOUNT_UUID;
        Cookies.set('tt-user-id', dummyAccountId ?? '', { expires: 365, sameSite: 'strict' });

        console.log({ e });

        if (
            e !== 'The user is not authenticated' &&
            e !== 'No Cognito Identity pool provided for unauthenticated access'
        ) {
            toast.error('There was an error getting your user details');
        }
    }
    setIsCheckingAuth(false);
};

export const useAuthEvents = () => {
    const { setUser, setUserCredentials, setIsCheckingAuth } = useTrackTitanStore((state) => state.auth);
    const { user, impersonationUserId } = useTrackTitanHydratedStore((state) => state.auth);

    const authEventCallback = async (authEvent: IAuthEvent) => {
        const { event, data } = authEvent.payload;

        if (impersonationUserId) {
            return;
        }

        switch (event) {
            case 'signIn':
            case 'cognitoHostedUI':
            case 'tokenRefresh':
                await getUser(setUser, setUserCredentials, setIsCheckingAuth);
                break;
            case 'linkedFederatedIdentity':
            case 'unlinkedFederatedIdentity':
                await getUser(setUser, setUserCredentials, setIsCheckingAuth, true);
                break;
            case 'oAuthSignOut':
            case 'signOut':
                setUser(undefined);
                break;
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
                console.warn('Sign in failure', data);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const hubListen = Hub.listen('auth', authEventCallback);

        if (!impersonationUserId && user === undefined) {
            getUser(setUser, setUserCredentials, setIsCheckingAuth);
        }

        return () => {
            hubListen();
        };
    }, [impersonationUserId]);
};
