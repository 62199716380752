import { colours } from 'src/tokens';
import { SegmentBreakdownType, SummaryTableData } from 'src/types';
import { RatingResponse } from 'src/types/new';
import { downloadGhostEvent } from 'src/analytics/layout/downloadGhost';

export const getTimeDiffColour = ({ withReferenceLap, time }: { time: number; withReferenceLap?: boolean }): string => {
    if (withReferenceLap === false || time === 0) return colours.white;
    if (time > 0) return colours.darkOrange;

    return colours.green;
};

// automatically download the file to the user browser when button is clicked
export const downloadDesktopApplication = (
    uuid: string,
    email: string,
    ghostEventDownloaded: boolean,
    setGhostEventDownloaded: (ghostEventDownloaded: boolean) => void
) => {
    if (window.fbq) {
        window.fbq('trackCustom', 'DownloadGhost', { uuid });
    }

    if (window.fbq && !ghostEventDownloaded) {
        window.fbq('trackCustom', 'DownloadGhostFirstTime', { uuid });
        setGhostEventDownloaded(true);
    }

    downloadGhostEvent(email, uuid);

    const isMacOS = navigator.userAgent.includes('Mac OS');

    const macDownloadLink = 'https://track-titan-ghost-public.s3.amazonaws.com/Track-Titan-Desktop-Application.dmg';
    const windowsDownloadLink = 'https://track-titan-ghost-public.s3.amazonaws.com/Track-Titan-Desktop-Application.exe';

    const link = isMacOS ? macDownloadLink : windowsDownloadLink;

    return window.open(link, '_self');
};

type analyticsParams = {
    data: SegmentBreakdownType[];
    segmentNumber: number;
    eventName: 'click_segment_table' | 'click_segment_map';
    uuid: string;
};

type analyticsEvent = {
    uuid: string;
    eventName: 'click_segment_table' | 'click_segment_map';
    data: {
        isSlowestSegment: boolean;
        isLargestDiffToRef?: boolean;
    };
};

export const buildAnalyticsEvent = ({ data, segmentNumber, uuid, eventName }: analyticsParams): analyticsEvent => {
    const slowestSegment = data.sort((a, b) => {
        if (a.time > b.time) return -1;
        if (a.time < b.time) return 1;

        return 0;
    })[0].segmentNumber;

    const diffToRefSegment =
        data[0].diffToRef === null
            ? undefined
            : data.sort((a, b) => {
                  if (a.diffToRef === null || b.diffToRef === null) return 0;
                  if (a.diffToRef > b.diffToRef) return -1;
                  if (a.diffToRef < b.diffToRef) return 1;

                  return 0;
              })[0].segmentNumber;

    const isSlowestSegment = slowestSegment === segmentNumber;
    const isLargestDiffToRef = diffToRefSegment === segmentNumber;

    return {
        uuid,
        eventName,
        data: {
            isSlowestSegment,
            isLargestDiffToRef: diffToRefSegment === undefined ? diffToRefSegment : isLargestDiffToRef
        }
    };
};

type formatSummaryTableTypes = {
    selectedUserLapTime: number;
    theoreticalBestTime: number | undefined;
    selectedReferenceLapTime?: number;
    showTheoreticalBest: boolean;
};

export const formatSummaryTableData = ({
    selectedUserLapTime,
    theoreticalBestTime,
    selectedReferenceLapTime,
    showTheoreticalBest
}: formatSummaryTableTypes): SummaryTableData[] => {
    const getDiffToRef = (time?: number) => {
        if (!time || !selectedReferenceLapTime) return null;

        return time - selectedReferenceLapTime;
    };

    const theoreticalBest = theoreticalBestTime || 0;

    if (!showTheoreticalBest) {
        return [
            {
                lapTime: selectedUserLapTime,
                diffToRef: getDiffToRef(selectedUserLapTime),
                stat: 'Fastest Lap time' as const
            }
        ];
    }

    return [
        {
            lapTime: selectedUserLapTime,
            diffToRef: getDiffToRef(selectedUserLapTime),
            stat: 'Fastest Lap time' as const
        },
        {
            lapTime: theoreticalBest,
            diffToRef: getDiffToRef(theoreticalBest),
            stat: 'Theoretical best' as const
        }
    ];
};

export const getFreeTrialCopy = (daysLeft: number) => {
    if (daysLeft === 0) {
        return 'Today is your last day in the free Plus Membership trial';
    } else {
        return `You have ${daysLeft} day${daysLeft > 1 ? 's' : ''} left on your free Plus Membership trial 🗓`;
    }
};

export const formatRatingResponse = (ratingResponse?: RatingResponse) => {
    if (!ratingResponse) return undefined;

    const clonedAndRounded = [...ratingResponse.history].map((item) => ({ ...item, rating: Math.round(item.rating) }));

    return {
        ...ratingResponse,
        rating: Math.round(ratingResponse.rating),
        history: clonedAndRounded.reverse()
    };
};

export const isCurrentRouteInList = (routeList: string[], currentRoute: string | null) => {
    return routeList.reduce((acc, curr) => {
        if (currentRoute && currentRoute.includes(curr)) {
            return true;
        }

        return acc;
    }, false);
};
