import { useEffect } from 'react';
import { getUserDetails } from 'src/queries/user/getUserDetails';
import { vulcanAuthApiRequest } from 'src/queries/utils';
import { UserType } from 'src/types';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import { useTrackTitanStore } from 'src/zustand/store';

export const getUsername = async (user: UserType): Promise<UserType> => {
    try {
        const { uuid, cognitoUsername } = user;

        // database data
        const data = await getUserDetails(uuid);

        const updatedUser = {
            ...user,
            displayName: ''
        };

        if (data?.username) {
            // if user already has a username in Aurora then use that
            updatedUser.displayName = data.username;
        } else if (cognitoUsername) {
            // if user already has a username in Cognito but not Aurora
            // then update database and use that
            await vulcanAuthApiRequest(
                `/users/${user!.uuid}`,
                user?.auth?.accessToken!,
                user?.auth?.accessTokenExpiresAt!,
                {
                    data: {
                        id: user!.uuid,
                        username: cognitoUsername
                    },
                    method: 'patch'
                }
            );

            updatedUser.displayName = cognitoUsername;
        }

        return updatedUser;
    } catch (error) {
        console.log(error);
        return { ...user, displayName: null };
    }
};

const setUpdatedUser = async (user: Promise<UserType>, setUser: (user: UserType | undefined) => void) => {
    const resolvedUser = await user;
    setUser(resolvedUser);
};

export const useGetUsername = () => {
    const { user, isCheckingAuth } = useTrackTitanHydratedStore((state) => state.auth);
    const { setUser } = useTrackTitanStore((state) => state.auth);

    useEffect(() => {
        // finished getting auth, got a user but no display name
        if (isCheckingAuth === false && user?.uuid && user.displayName === undefined) {
            setUpdatedUser(getUsername(user), setUser);
        }
    }, [getUsername, isCheckingAuth, user]);
};
