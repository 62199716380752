import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { RawUserType } from 'src/types';
import { isEmpty } from 'lodash';
import { VulcanResponse } from './types';

export const nextApiRequest = async <T = any>(url: string) => (await axios.get<T>(`/api${url}`)).data;

export const apiRequest = async <T = any>(
    url: string,
    baseUrl?: string,
    options: AxiosRequestConfig = {},
    serverCacheTime = 0
) => {
    const isServer = typeof window === 'undefined';

    if (isServer && isEmpty(options)) {
        const response = await fetch(`${baseUrl}${url}`, {
            next: { revalidate: serverCacheTime }
        });

        return (await response.json()) as T;
    }

    return (await axios.request<T>({ url: `${baseUrl}${url}`, ...options })).data;
};

const authApiRequest = async <T = any>(
    baseUrl: string,
    url: string,
    accessToken: string,
    accessTokenExpiresAt: number,
    options: AxiosRequestConfig = {},
    useAccessToken = false
) => {
    const request = (accessToken: string) =>
        axios.request<T>({
            url: `${baseUrl}${url}`,
            headers: { ...options.headers, Authorization: accessToken },
            ...options
        });
    if (!useAccessToken) {
        if (Date.now() > accessTokenExpiresAt * 1000) {
            console.log('Refreshing access token');
            const userData: RawUserType = await Auth.currentAuthenticatedUser();
            const newAccessToken = userData.signInUserSession.idToken.jwtToken;

            // This should trigger the refresh of the access token stored in zustand
            await Auth.currentSession();

            return (await request(newAccessToken)).data;
        }
    } else {
        const creds = await Auth.currentSession();
        accessToken = creds.getAccessToken().getJwtToken();
    }

    return (await request(accessToken)).data;
};

export const gaiaAuthApiRequest = async <T = any>(
    url: string,
    accessToken: string,
    accessTokenExpiresAt: number,
    options: AxiosRequestConfig = {}
) => {
    return await authApiRequest(
        `${process.env.NEXT_PUBLIC_GAIA_API}/api/v1`,
        url,
        accessToken,
        accessTokenExpiresAt,
        options
    );
};

export const gaiaApiRequest = async <T = any>(url: string, options: AxiosRequestConfig = {}, serverCacheTime = 0) =>
    await apiRequest<T>(
        url,
        `${process.env.NEXT_PUBLIC_GAIA_API ?? process.env.REMOTION_NEXT_PUBLIC_GAIA_API}/api/v1`,
        options,
        serverCacheTime
    );

export const vulcanApiRequest = async <T = any>(
    url: string,
    options: AxiosRequestConfig = {},
    serverCacheTime = 0
): Promise<VulcanResponse<T>> => {
    try {
        return await apiRequest<VulcanResponse<T>>(
            url,
            `${process.env.NEXT_PUBLIC_VULCAN_API ?? process.env.REMOTION_NEXT_PUBLIC_VULCAN_API}/api/v1`,
            options,
            serverCacheTime
        );
    } catch (error) {
        const axiosError = error as AxiosError;

        return {
            success: false,
            status: axiosError.response?.status ?? 500,
            message: axiosError.response?.statusText ?? 'Something went wrong',
            data: null
        };
    }
};

export const vulcanAuthApiRequest = async <T = any>(
    url: string,
    accessToken: string,
    accessTokenExpiresAt: number,
    options: AxiosRequestConfig = {}
) => {
    try {
        return await authApiRequest<VulcanResponse<T>>(
            `${process.env.NEXT_PUBLIC_VULCAN_API ?? process.env.REMOTION_NEXT_PUBLIC_VULCAN_API}/api/v1`,
            url,
            accessToken,
            accessTokenExpiresAt,
            options,
            true
        );
    } catch (error) {
        const axiosError = error as AxiosError;

        return {
            success: false,
            status: axiosError.response?.status ?? 500,
            message: axiosError.response?.statusText ?? 'Something went wrong',
            data: null
        };
    }
};

export const newsApiRequest = async <T = any>(url: string, options: AxiosRequestConfig = {}, serverCacheTime = 0) =>
    apiRequest<T>(url, process.env.NEXT_PUBLIC_NEWS_API, options, serverCacheTime);
